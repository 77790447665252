import React from "react";
import { useFormik } from "formik";
import {
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomSelect,
  CustomTextField,
} from "../../../../components/formsComponents";
import { useDispatch, useSelector } from "react-redux";
import { IStoreState } from "../../../../redux/initialStoreState";

import { addPersonalInformationDialogAsync } from "../../../../redux/customer/customerActions";
import { Dialog } from "../../../../components/Dialogs/Dialog";
import { useNavigate } from "react-router-dom";
import { PhoneTextField } from "../../../../components/PhoneTextField/PhoneTextField";
import { IAddRequestFormDialogProps } from "./AddRequestFormDialog.types";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../components/LocationAutoComplete/LocationAutoComplete";
import { fetchAddRequestFormAsync } from "../../../../redux/allApplications/allApplicationsActions";
import { fetchRolesListAsync } from "../../../../redux/security/securityActions";
import { BranchCodeAutoSearch } from "../../../../components/AutoCompleteSearches/BranchCodeAutoSearch";
import { renderDropdownValue } from "../../../../helpers";

export const RequestFormDialog: React.FC<IAddRequestFormDialogProps> = (
  props
) => {
  const { open, onClose, data } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const list = useSelector(
    (storeState: IStoreState) => storeState.security.roles.list
  );

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.first_name) {
        errors.first_name = "First Name is required";
      } else if (!values.last_name) {
        errors.last_name = "Last Name is required";
      } else if (!values.email) {
        errors.email = "*Email is requried.";
      } else if (
        values.email !== "" &&
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          values.email
        )
      ) {
        errors.email = "*Invalid email address.";
      } else if (!values.phone) {
        errors.phone = "*Phone is requried.";
      } else if (values.phone.length !== 10) {
        errors.phone = "*Phone number should be 10 digits.";
      }
      return errors;
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      dispatch(
        fetchAddRequestFormAsync(values, (isSuccess) => {
          if (isSuccess) {
            onClose();
          }
          setSaveLoading(false);
        })
      );
    },
  });

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      address: data.address,
      city: data.city,
      country: data.country,
      province_or_state: data.state,
      postal_code: data.postalCode,
    });
  };

  React.useEffect(() => {
    dispatch(fetchRolesListAsync());
  }, []);

  return (
    <Dialog
      open={open}
      title="Add Request Form"
      onClose={onClose}
      size="md"
      actions={[
        {
          type: "button",
          label: "Close",
          variant: "contained",
          onClick: onClose,
        },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
          onClick: handleSubmit,
        },
      ]}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Type</CustomFormLabel>
            <ControlledCustomSelect
              name="type"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              displayEmpty
              placeholder="Select One"
              options={[
                { label: "Add User", value: "ADD USER" },
                { label: "Delete User", value: "DELETE USER" },
              ].map((data) => {
                return { label: data.label, value: data.value };
              })}
              value={values.type}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Branch Name</CustomFormLabel>
            <BranchCodeAutoSearch
              value={values.branch_name}
              onSelect={(value) => {
                setFieldValue("branch_name", value.value);
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <CustomFormLabel>Role</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={Number(values.role_id)}
              name="role_id"
              onChange={handleChange}
              placeholder="Select One"
              displayEmpty
              options={list.map((role) => {
                return { label: role.role_name, value: role.role_id };
              })}
            ></ControlledCustomSelect>
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>First Name</CustomFormLabel>
            <CustomTextField
              id="first_name"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.first_name}
              error={errors.first_name ? true : false}
              helperText={errors.first_name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Last Name</CustomFormLabel>
            <CustomTextField
              id="last_name"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.last_name}
              error={errors.last_name ? true : false}
              helperText={errors.last_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Personal Email</CustomFormLabel>
            <CustomTextField
              id="email"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.email}
              error={errors.email ? true : false}
              helperText={errors.email}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Phone Number (+1)</CustomFormLabel>
            <PhoneTextField
              id="phone"
              variant="outlined"
              size="small"
              fullWidth
              value={values.phone}
              error={errors.phone ? true : false}
              helperText={errors.phone}
              setFieldValue={setFieldValue}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Personal Address</CustomFormLabel>
            <LocationAutoComplete
              id="address"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.address}
              onLocationChange={handleAddress}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Unit/Suite</CustomFormLabel>
            <CustomTextField
              name="unit_or_suite"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.unit_or_suite}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>City</CustomFormLabel>
            <CustomTextField
              name="city"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.city}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Province/State</CustomFormLabel>
            <CustomTextField
              name="province_or_state"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.province_or_state}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Country</CustomFormLabel>
            <CustomTextField
              name="country"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.country}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Postal Code</CustomFormLabel>
            <CustomTextField
              name="postal_code"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.postal_code}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Prior Brokerage</CustomFormLabel>
            <CustomTextField
              name="prior_brokerage"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.prior_brokerage}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={9}>
            <CustomFormLabel>Details</CustomFormLabel>
            <CustomTextField
              name="details"
              variant="outlined"
              size="small"
              type="text"
              fullWidth
              value={values.details}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Status</CustomFormLabel>
            <ControlledCustomSelect
              fullWidth
              value={values.status}
              name="status"
              onChange={handleChange}
              placeholder="Select One"
              displayEmpty
              options={[
                { label: "New", value: "Active" },
                { label: "Inactive", value: "Inactive" },
                { label: "Completed", value: "Completed" },
              ].map((template) => {
                return { label: template.label, value: template.value };
              })}
            ></ControlledCustomSelect>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table
            aria-label="collapsible table"
            sx={{
              whiteSpace: {
                xs: "nowrap",
                sm: "unset",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h5" fontWeight={700}>
                    Requirements
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "55%",
                  }}
                >
                  Server
                </TableCell>

                <TableCell>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="server"
                    value={values.server}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["Y", "N"].map((requiredSelection) => {
                      return (
                        <MenuItem value={requiredSelection}>
                          {requiredSelection}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "55%",
                  }}
                >
                  Powerbroker
                </TableCell>

                <TableCell>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="powerbroker"
                    value={values.powerbroker}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["Y", "N"].map((requiredSelection) => {
                      return (
                        <MenuItem value={requiredSelection}>
                          {requiredSelection}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "55%",
                  }}
                >
                  Portal
                </TableCell>

                <TableCell>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="portal"
                    value={values.portal}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["Y", "N"].map((requiredSelection) => {
                      return (
                        <MenuItem value={requiredSelection}>
                          {requiredSelection}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "55%",
                  }}
                >
                  Applied Rating Services
                </TableCell>

                <TableCell>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="applied_rating_services"
                    value={values.applied_rating_services}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["Y", "N"].map((requiredSelection) => {
                      return (
                        <MenuItem value={requiredSelection}>
                          {requiredSelection}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "55%",
                  }}
                >
                  Insurer Portal IDs
                </TableCell>

                <TableCell>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="insurer_portal_ids"
                    value={values.insurer_portal_ids}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["Y", "N"].map((requiredSelection) => {
                      return (
                        <MenuItem value={requiredSelection}>
                          {requiredSelection}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "55%",
                  }}
                >
                  Notify RIBO
                </TableCell>

                <TableCell>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="notify_ribo"
                    value={values.notify_ribo}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["Y", "N", "New Applicant"].map((requiredSelection) => {
                      return (
                        <MenuItem value={requiredSelection}>
                          {requiredSelection}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: "55%",
                  }}
                >
                  Order Business Card
                </TableCell>

                <TableCell>
                  <CustomSelect
                    variant="outlined"
                    size="small"
                    type="string"
                    name="order_business_card"
                    value={values.order_business_card}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    renderValue={renderDropdownValue(`Select one`)}
                  >
                    {["Y", "N"].map((requiredSelection) => {
                      return (
                        <MenuItem value={requiredSelection}>
                          {requiredSelection}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </form>
    </Dialog>
  );
};
